import React from 'react'
import { Button } from '@liquid-design/liquid-design-react'
import Section from '../Section/Section'
import { useStoreSubscribe } from 'store/subscribe'
import SubscribeModal from '../SubscribeModal/SubscribeModal'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const Subscribe: React.FC<Props> = (props) => {
  const cn = `${props.className || ''} landing-page__sign-up`

  const openSubscribeModal = () => {
    useStoreSubscribe.setState({
      isSubscribeModalOpen: true,
    })
  }

  return (
    <div className={cn}>
      <Section>
        <div className="flex justify-center md:w-2/3 mx-auto">
          <div className="flex flex-col items-center md:-mx-4 relative rounded-xl shadow-xl bg-white w-screen py-6 -mx-3 px-3 md:mx-0 md:px-6">
            <p className="landing-page__p leading-relaxed text-center text-lg md:text-2xl pb-8 md:pb-12">
              Are you interested in&nbsp;KnoraGraph?
              <br />
              Sign up and be among the first to try&nbsp;it&nbsp;out!
            </p>
            <Button
              data-test="btn-cta-sign-up"
              className="w-full sm:w-3/4 xl:w-1/2 mb-3"
              size="big"
              onClick={openSubscribeModal}
            >
              Stay up to date
              <span className="hidden sm:inline"> about KnoraGraph</span>
            </Button>
          </div>
        </div>
        <SubscribeModal />
      </Section>
    </div>
  )
}

export default Subscribe
