import React from 'react'
import { Card, Headline, Paragraph } from '@liquid-design/liquid-design-react'
import Section from '../Section/Section'
import SectionHeader from '../Section/SectionHeader'

import DataManagement from './assets/data-management.svg'
import SaveTime from './assets/save-time.svg'
import WorkflowIntegration from './assets/workflow-integration.svg'

import './CoreFeatures.css'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const CoreFeatures: React.FC<Props> = (props) => {
  const cn = `${props.className || ''}`

  const DataManagementIcon = DataManagement as any
  const SaveTimeIcon = SaveTime as any
  const WorkflowIntegrationIcon = WorkflowIntegration as any

  return (
    <div className={cn}>
      <Section>
        <SectionHeader
          className="text-center"
          headline={'Core Features'}
          caption={'The benefits of KnoraGene'}
        />

        <div className="md:grid grid-flow-col grid-cols-3 grid-rows-1 gap-5 mb-36 md:mb-44">
          <Card className="core-features__card text-center" active>
            <DataManagementIcon className="mx-auto mb-3" />
            <Headline className="pb-2" type="H3">
              Data Management
            </Headline>
            <Paragraph type="sm">
              Overview dashboard of all your uploads and extracted genes so you
              won’t waste time during your research&nbsp;anymore.
            </Paragraph>
          </Card>

          <Card className="core-features__card text-center" active>
            <SaveTimeIcon className="mx-auto mb-3" />
            <Headline className="pb-2" type="H3">
              Save Time
            </Headline>
            <Paragraph type="sm">
              Batch-upload multiple PDFs and PubMed publications at&nbsp;once.
            </Paragraph>
          </Card>

          <Card className="core-features__card text-center" active>
            <WorkflowIntegrationIcon className="mx-auto mb-3" />
            <Headline className="pb-2" type="H3">
              Workflow Integration
            </Headline>
            <Paragraph type="sm">
              Extracted genes are come with NCBI compatible context and are
              ready to be exported for your&nbsp;convenience.
            </Paragraph>
          </Card>
        </div>
      </Section>
    </div>
  )
}

export default CoreFeatures
