import create from 'zustand'

type State = {
  isSubscribeModalOpen: boolean
  setIsSubscribeModalOpen: (isOpen: boolean) => void
}
export const useStoreSubscribe = create<State>((set) => ({
  isSubscribeModalOpen: false,
  setIsSubscribeModalOpen(isOpen: boolean) {
    set((state) => ({ isSubscribeModalOpen: isOpen }))
  },
}))
