import React from 'react'
import { Paragraph } from '@liquid-design/liquid-design-react'
import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'
import RapidAutomatedExtraction from './assets/rapid-automated-extraction.svg'
import OnlyValidData from './assets/only-valid-data.svg'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const LiteratureSearch: React.FC<Props> = (props) => {
  const cn = `${props.className || ''}`

  const RapidAutomatedExtractionIcon = RapidAutomatedExtraction as any
  const OnlyValidDataIcon = OnlyValidData as any

  return (
    <div className={cn}>
      <Section>
        <SectionHeader
          className="mb-12"
          headline={'No Need to Manually Scan Publications for Genes Anymore'}
          caption={'Scientific literature research'}
        />

        <div className="md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-10 mb-36 md:mb-44">
          <div className="flex items-center pb-8">
            <RapidAutomatedExtractionIcon className="mr-6 w-1/3 flex-shrink-0" />
            <div>
              <Paragraph className="pb-3" type="xl">
                Rapid Automated Extraction
              </Paragraph>
              <Paragraph type="sm">
                KnoraGene is able to handle up to <b>100 PDF files</b> and{' '}
                <b>100 PubMed links</b> at the same time. The extraction takes
                about&nbsp;<b>60&nbsp;seconds</b>.
              </Paragraph>
            </div>
          </div>

          <div className="flex items-center pb-8">
            <OnlyValidDataIcon className="mr-6 w-1/3 flex-shrink-0" />
            <div>
              <Paragraph className="pb-3" type="xl">
                Only Valid Data
              </Paragraph>
              <Paragraph type="sm">
                KnoraGene is pulling results from trusted sources such as{' '}
                <b>NCBI, PubMed, or Medline</b>. Finally,
                no&nbsp;more&nbsp;double-checking!
              </Paragraph>
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default LiteratureSearch
