import React from 'react'
import Section from '../Section/Section'
import SectionHeader from '../Section/SectionHeader'
import { Paragraph } from '@liquid-design/liquid-design-react'

import image from './assets/extraction.png'
import ListItemNumber from './ListItemNumber'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const HowItWorks: React.FC<Props> = (props) => {
  const cn = `${props.className || ''}`

  return (
    <div className={cn}>
      <Section>
        <SectionHeader
          className="text-center mb-12"
          headline={'Connected Data to Improve Your Research Process'}
          caption={'How KnoraGene is working'}
        />

        <div className="md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-6 mb-24 md:mb-36">
          <div className="mb-16 md:pr-16">
            <img
              className="w-full h-auto rounded-lg shadow-lg max-w-sm md:max-w-full mx-auto"
              src={image}
              alt="Extraction in progress"
            />
          </div>

          <div className="md:pr-8 flex items-center">
            <ol className="mx-auto md:mx-0">
              <li className="flex mb-10 md:mb-16">
                <ListItemNumber>1</ListItemNumber>
                <Paragraph className="w-80" type="lg">
                  Upload PDFs or add PubMed IDs.
                </Paragraph>
              </li>
              <li className="flex mb-10 md:mb-16">
                <ListItemNumber>2</ListItemNumber>
                <Paragraph className="w-80" type="lg">
                  Extract genes.
                </Paragraph>
              </li>
              <li className="flex mb-10 md:mb-16">
                <ListItemNumber>3</ListItemNumber>
                <Paragraph className="w-80" type="lg">
                  Review the detailed gene&nbsp;information.
                </Paragraph>
              </li>
              <li className="flex mb-4 md:mb-6">
                <ListItemNumber>4</ListItemNumber>
                <Paragraph className="w-80" type="lg">
                  Export your extracted genes to CSV. Either individually,
                  or&nbsp;as&nbsp;a&nbsp;batch.
                </Paragraph>
              </li>
            </ol>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default HowItWorks
