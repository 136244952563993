import React from 'react'
import { Paragraph } from '@liquid-design/liquid-design-react'
import Section from '../Section/Section'
import SectionHeader from '../Section/SectionHeader'

import Microscope from './assets/microscope.svg'
import Molecule from './assets/molecule.svg'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const AboutTheTeam: React.FC<Props> = (props) => {
  const cn = `${props.className || ''}`

  const MicroscopeIcon = Microscope as any
  const MoleculeIcon = Molecule as any

  return (
    <div className={cn}>
      <Section className="py-24">
        <SectionHeader
          className="mb-12"
          headline={
            'We want to help scientists around the world to focus on the important parts of their research.'
          }
          caption={'About the team'}
          captionClassName={'text-rich-blue'}
        />

        <div className="md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-6 mb-16 md:mb-24">
          <div className="mb-12 md:pr-8">
            <MicroscopeIcon className="mb-4" />
            <Paragraph type="lg" className="mb-6">
              Repetitive and time consuming tasks can delay your&nbsp;success.
            </Paragraph>
            <Paragraph type="sm">
              At the beginning of every scientific literature process, it is
              important to get an overview of relevant publications, so we
              understand what research already exists. This is especially
              important for genetics, who already investigated
              certain&nbsp;genes.
              <br />
              <br />
              KnoraGene assists you while you work on the actual project instead
              of hand selecting every single gene mentioned within all of the
              publications you need to read&nbsp;through.
            </Paragraph>
          </div>

          <div className="md:pr-8">
            <MoleculeIcon className="mb-4" />
            <Paragraph type="lg" className="mb-6">
              Future-ready technology meets the bench, don’t stay&nbsp;behind!
            </Paragraph>
            <Paragraph type="sm">
              Our team of scientists, designers, and developers share a heart
              for scientific research and put all of their passion into
              KnoraGene. Thank you for using our beta version to help us make
              this your go-to tool for gene extraction.
              <br />
              <br />
              In future releases, we are planning to make your research data
              even more tangible and connected with&nbsp;KnoraGraph.
            </Paragraph>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default AboutTheTeam
