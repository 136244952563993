import React from 'react'
import {
  Button,
  Headline,
  Paragraph,
  Theme,
} from '@liquid-design/liquid-design-react'
import Section from '../Section/Section'

import Alembic from './assets/alembic.svg'
import BetaOfferingSVG from './assets/beta-offering.svg'
import SectionHeader from '../Section/SectionHeader'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const BetaOffering: React.FC<Props> = (props) => {
  const cn = `${props.className || 'landing-page__beta-offering'}`

  const AlembicIcon = Alembic as any
  const BetaOfferingImage = BetaOfferingSVG as any

  return (
    <div className={cn}>
      <Section>
        <div className="landing-page__shape landing-page__shape--right landing-page__shape--beta-offering z-0" />
        <div className="relative rounded-xl shadow-xl bg-white -mx-3 px-3 md:-mx-8 md:px-8 xl:-mx-10 xl:px-10 2xl:-mx-14 2xl:px-14 flex flex-col md:flex-row mb-20">
          <div className="mx-auto max-w-2/3 -mt-14 mb-2 md:-mb-10 flex justify-center md:order-2 md:w-1/2 md:pl-2.5">
            <AlembicIcon />
          </div>
          <div className="py-8 md:order-1 md:w-1/2 md:pr-2.5">
            <Headline className="pb-2" type="H3">
              KnoraGene helps you get organized at early stage
              scientific&nbsp;research.
            </Headline>
            <Paragraph className="pb-10" type="sm">
              Upload the publications that you want to scan and after clicking
              one button, you will see a list of all genes that are mentioned
              within your&nbsp;documents.
            </Paragraph>
            <Theme themeName="vibrantCyan" className="mb-2">
              <Button
                data-test="btn-cta-beta-offering"
                size="big"
                className="w-full"
              >
                Start Right Away
              </Button>
            </Theme>
          </div>
        </div>
        <div>
          <SectionHeader
            className="text-center mb-16"
            headline={<span className="text-white">Beta offering</span>}
            caption={'KnoraGene Beta'}
          />
          <div className="md:flex justify-center items-center pb-24 md:pb-44">
            <div className="md:w-1/4 max-w-sm mx-auto pr-4">
              <Paragraph
                type="xl"
                className="text-center md:text-right mb-12 md:mb-24"
              >
                <span className="text-vibrant-cyan">
                  A free gene database with 1000s&nbsp;of&nbsp;entries.
                </span>
              </Paragraph>
              <Paragraph type="xl" className="text-center md:text-right">
                <span className="text-vibrant-cyan">
                  Free access to your gene and publication library 24/7.
                </span>
              </Paragraph>
            </div>
            <BetaOfferingImage className="max-w-full h-2/3 md:h-auto mx-auto my-12 md:w-1/2" />
            <div className="md:w-1/4 max-w-sm mx-auto pl-4">
              <Paragraph
                type="xl"
                className="text-center md:text-left mb-12 md:mb-24"
              >
                <span className="text-vibrant-cyan">
                  Full control over your extracted data and all genes.
                </span>
              </Paragraph>
              <Paragraph type="xl" className="text-center md:text-left">
                <span className="text-vibrant-cyan">
                  Report errors and&nbsp;submit feature&nbsp;requests.
                </span>
              </Paragraph>
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default BetaOffering
