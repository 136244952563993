import React from 'react'
import Section from 'components/LandingPage/Section/Section'
import {
  Button,
  Headline,
  Paragraph,
  Theme,
} from '@liquid-design/liquid-design-react'
import { Link } from 'gatsby'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const Hero: React.FC<Props> = (props) => {
  const cn = `${props.className || ''}`
  return (
    <div className={cn}>
      <Section>
        <div className="flex">
          <div className="flex items-center py-16 md:py-24 mt-56 md:mt-0 md:w-1/2">
            <div className="max-w-sm">
              <Headline type="H1" className="pb-4">
                Extract Genes from Scientific Publications
              </Headline>
              <Paragraph className="pb-12">
                Upload your publications or PubMed links and start working with
                all gene entries within your&nbsp;files.
              </Paragraph>
              <Theme themeName="vibrantCyan">
                <Link to="/database">
                  <Button data-test="btn-cta-hero" className="mb-4" size="big">
                    Start Your First Extraction
                  </Button>
                </Link>
              </Theme>
              <Paragraph className="mb-8" type="xs">
                <span className="text-rich-black-lighter">
                  Try the beta version for free today!
                  <br />
                  <i>* For a limited time only.</i>
                </span>
              </Paragraph>
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default Hero
