import React, { ReactNode } from 'react'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
  children?: ReactNode
}

const ListItemNumber: React.FC<Props> = (props) => {
  const cn = `${
    props.className || ''
  } inline-flex flex-shrink-0 mr-4 -mt-2 w-12 h-12 text-xl items-center justify-center rounded-full bg-rich-blue-darkest text-white font-black`
  return <span className={cn}>{props.children}</span>
}

export default ListItemNumber
