import React from 'react'
import { withPrefix } from 'gatsby'
import { Headline, Paragraph } from '@liquid-design/liquid-design-react'
import SectionHeader from '../Section/SectionHeader'
import Section from '../Section/Section'

import Lock from './assets/lock.svg'
import Lightning from './assets/lightning.svg'
import Puzzle from './assets/puzzle.svg'

const movie = withPrefix('/videos/knowledge-graph-teaser.mp4')

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
}

const KnowledgeGraphs: React.FC<Props> = (props) => {
  const cn = `${props.className || ''}`

  const LockIcon = Lock as any
  const LightningIcon = Lightning as any
  const PuzzleIcon = Puzzle as any

  return (
    <div className={cn}>
      <Section className="mb-32 md:mb-48">
        <SectionHeader
          className="text-center mb-12"
          headline={
            'Connected Knowledge to Revolutionize Scientific Literature Research'
          }
          caption={'Knowledge graphs'}
        />

        <p className="landing-page__p leading-relaxed mb-24 md:mb-32 text-center md:px-24 text-lg md:text-2xl">
          We know that it can be challenging to keep a cool head while juggling
          hundreds of topics at&nbsp;the same time and trying to keep an
          overview on everything you&nbsp;already&nbsp;read.
          <br />
          <br />
          With KnoraGraph, you will be able to streamline your research in
          a&nbsp;single&nbsp;view.
        </p>

        <div className="relative flex justify-center">
          <div className="landing-page__shape landing-page__shape--video inset-0 h-0 absolute"></div>
          <video
            className="rounded-xl shadow-xl"
            controls={false}
            autoPlay
            loop
            playsInline
            muted
          >
            <source src={movie} type="video/mp4" />
          </video>
        </div>
      </Section>
      <Section className="mb-24 md:mb-32">
        <SectionHeader
          className="text-center mb-12 md:mb-24"
          headline={'Knowledge Graph Key Features'}
        />

        <div className="md:grid grid-flow-col grid-cols-3 grid-rows-1 gap-5 text-center">
          <div>
            <LockIcon className="mx-auto mb-3 h-32 mb-8 md:mb-12" />
            <Paragraph type="sm" className="w-48 mx-auto mb-16">
              Retrieve previous research states and automatically create a
              “back-up” of your scientific literature&nbsp;research
            </Paragraph>
          </div>

          <div>
            <LightningIcon className="mx-auto mb-3 h-44 mb-6 -mt-6" />
            <Paragraph type="sm" className="w-48 mx-auto mb-16">
              Share your current research state with your&nbsp;co-workers
            </Paragraph>
          </div>

          <div>
            <PuzzleIcon className="mx-auto mb-3 h-32 mb-8 md:mb-12" />
            <Paragraph type="sm" className="w-48 mx-auto mb-16">
              Discover interesting genes and publications that you would have
              missed&nbsp;otherwise
            </Paragraph>
          </div>
        </div>
      </Section>
    </div>
  )
}

export default KnowledgeGraphs
