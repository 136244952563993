import React from 'react'
import { modalStyles } from 'utils/modalStyles'
import { LightBox } from '@liquid-design/liquid-design-react'
import { useStoreSubscribe } from 'store/subscribe'

import './SubscribeModal.css'

interface Props {
  ref?: React.RefObject<HTMLDivElement>
  className?: string
  style?: React.CSSProperties
  id?: string
}

const SubscribeModal = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const cn = `${props.className || ''}`

  const isSubscribeModalOpen = useStoreSubscribe(
    (state) => state.isSubscribeModalOpen
  )
  const setIsSubscribeModalOpen = useStoreSubscribe(
    (state) => state.setIsSubscribeModalOpen
  )

  return (
    <div ref={ref} className={cn}>
      <LightBox
        className="subscribe-modal"
        open={isSubscribeModalOpen}
        onClose={() => {
          setIsSubscribeModalOpen(false)
        }}
        reactModalProps={{
          style: modalStyles,
        }}
      >
        <>
          <iframe
            title="Subscribe to Knora Gene"
            className="block mx-auto max-w-full"
            width="540"
            height="575"
            src="https://f086aa41.sibforms.com/serve/MUIEAJK3RWp5ElsDoYHFFgWZuTJ0YDSRculcpEOZEyTVfIWes2bhJ_CgrtwxbtuCT2HJEBM4gauec4eBxyp9HYUBla6lfvwp6F4J0RxhhHqPG7eL27HRq9hivib8c2HflQ110u4yKgY1zjswy7ammjMfAcpLZNRaUYehpHDLujxa8Ss9QuN4182caemB0GRbmKXIAT67L5r3O0w0"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
          ></iframe>
        </>
      </LightBox>
    </div>
  )
})

export default SubscribeModal
