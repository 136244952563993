import React from 'react'
import LayoutLandingPage from 'layouts/LayoutLandingPage/LayoutLandingPage'
import Header from 'components/LandingPage/Header/Header'
import Hero from 'components/LandingPage/Hero/Hero'
import CoreFeatures from 'components/LandingPage/CoreFeatures/CoreFeatures'
import LiteratureSearch from 'components/LandingPage/LiteratureSearch/LiteratureSearch'
import BetaOffering from 'components/LandingPage/BetaOffering/BetaOffering'
import AboutTheTeam from 'components/LandingPage/AboutTheTeam/AboutTheTeam'
import HowItWorks from 'components/LandingPage/HowItWorks/HowItWorks'
import KnowledgeGraphs from 'components/LandingPage/KnowledgeGraphs/KnowledgeGraphs'
import Subscribe from 'components/LandingPage/Subscribe/Subscribe'

import 'components/LandingPage/LandingPage.css'

export default function Privacy() {
  return (
    <LayoutLandingPage className="landing-page">
      <div className="landing-page__shape landing-page__shape--right landing-page__shape--header" />
      <Header />
      <Hero />
      <div className="landing-page__shape landing-page__shape--left landing-page__shape--core-features" />
      <CoreFeatures />
      <LiteratureSearch />
      <BetaOffering />
      <AboutTheTeam />
      <div className="landing-page__shape landing-page__shape--right landing-page__shape--how-it-works" />
      <HowItWorks />
      <KnowledgeGraphs />
      <Subscribe />
    </LayoutLandingPage>
  )
}
