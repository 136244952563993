import React, { ReactNode } from 'react'
import { Headline } from '@liquid-design/liquid-design-react'

interface Props {
  className?: string
  style?: React.CSSProperties
  id?: string
  headline: ReactNode
  caption?: string
  captionClassName?: string
}

const SectionHeader: React.FC<Props> = (props) => {
  const cn = `${props.className || ''}`
  return (
    <header className={cn}>
      {props.caption && (
        <p
          className={`${
            props.captionClassName || 'text-vibrant-cyan'
          } uppercase tracking-widest font-black text-xs mb-3`}
          aria-label={props.caption}
        >
          {props.caption}
        </p>
      )}
      <Headline type="H2" className="pb-4">
        {props.headline}
      </Headline>
    </header>
  )
}

export default SectionHeader
